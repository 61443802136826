// ##############################################
    // #################### forms ###################
    // ############################################## 
    
    $('input, textarea').each(function () {
        if ($(this).val() != '') {
            $(this).addClass('has-content');
        
        } else {
            $(this).removeClass('has-content');
        }
    });
    $('input, textarea').on('focusin', function () {
        $(this).addClass('focus');
    });
    $('input, textarea').on('focusout', function () {
        $(this).removeClass('focus');
        if ($(this).val() != '') {
            $(this).addClass('has-content');
        } else {
            $(this).removeClass('has-content');
        }
    });
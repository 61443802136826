import './jquery';

$(function () {
    
    $('.fullscreen-slider').each(function () {
        var slider = $(this);
        slider.slick({
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 750,
            autoplay: false,
            autoplayspeed: 750,
            
            nextArrow: $('.next'),
            prevArrow: $('.prev'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        arrows: true,
                    }
                },

            ]
        })
        slider.on('setPosition', function () {
            $('.statement-image').matchHeight();
            if($(window).width() < 400){
                var sliderHeight = slider.find('.half-fullscreen').first().outerHeight();
                var positionTop = (sliderHeight) + 'px';
                $('.fullscreen-slider-outer').find('.next').css('top', positionTop);
                $('.fullscreen-slider-outer').find('.prev').css('top', positionTop);
            }
            // slideHeight();
        });

    });
       

    $(window).on('resize',function () {
        $('.teaser-slider')[0].slick.refresh();
        $('.fullscreen-slider')[0].slick.refresh();
        // slideHeight();
        $('.statement-image').matchHeight();
    });

    // 4-Cols-Slider
    $('.teaser-outer .teaser-slider').each(function () {
        var slider = $(this);
        var nextSlide = $('.slide-next');
        var prevSlide = $('.slide-prev');
        slider.slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            arrows: false,
            nextArrow: false,
            prevArrow: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                        infinite: true,
                        arrows: true,
                        adaptiveHeight: true,
                        rows: 0,
                        nextArrow: nextSlide,
                        prevArrow: prevSlide,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        dots: false,
                        infinite: true,
                        arrows: true,
                        nextArrow: nextSlide,
                        prevArrow: prevSlide,
                    }
                }
            ]
        }).on('setPosition', function (event, slick) {
	        var imgHeight = slider.find('.testimonial-portrait').first().outerHeight();
            var positionTop = (imgHeight) + 'px';
            $('.teaser-outer').find('.slide-next').css('top', positionTop);
            $('.teaser-outer').find('.slide-prev').css('top', positionTop);
           
        });
    });

});

 
   

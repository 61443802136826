import './jquery';

import 'bootstrap';
import 'slick-carousel';
import './slider';
import './contactform';
import 'jquery-match-height';


$(function () { 

  // ##############################################
  // ################# Nav #######################
  // ##############################################

  $(window).on('resize',function () {
    $('header').outerHeight();
});

  $('#navbarNav .nav-item').on('click',function(){

    $(this).parents('#navbarNav').removeClass('show');
    $(this).parents('#navbarNav').siblings('button').addClass('collapsed');

  });

  var $navOffset = $('header').outerHeight();
  $('#navbarNav .nav-item').on('click',function(){
    let anchor = $(this).children().attr('href');
    scrollToTop(anchor);
  })

  function scrollToTop(id) {
    let scrollSection;
    if(id == '#about'){
      scrollSection = $(id).offset().top;
    }
    else if(id == '#spenden'){
        return false;
    }
    else{
      scrollSection = ($(id).offset().top - ($navOffset))
    }
    $("html, body").animate({
      scrollTop: scrollSection
  }, 1000);
    
  }

  $('.fullscreen-slider .btn-primary').on('click',function(){
    let anchor = $(this).attr('href');
    scrollToTop(anchor);
  })

  // ##############################################
  // ################# Accordion ##################
  // ##############################################
  var $navOffset = $('header').outerHeight();
  $('.accordion .collapse').on('shown.bs.collapse', function (e) {
      
    //scroll active accordion to top
    var id = $(e.target).parent().find("[id]")[0].id;
    var cardHeader = $("#" + id);
    var parentItem = cardHeader.closest('.card');

    var win = $(window);
    var viewport = {
      top: win.scrollTop(),
    };
    var bounds = parentItem.offset();
    var itemHeight = parentItem.outerHeight();
    if ((itemHeight) > (win.height()) || ((viewport.top) + ((win.height()) / 2)) < (bounds.top) || bounds.top < ((viewport.top) + ($navOffset))) {
      $('html, body').animate({
        scrollTop: ($(parentItem).offset().top - ($navOffset))
      }, 800);
    }
  });

  $('input, textarea').each(function () {
    if ($(this).val() != '') {
        $(this).addClass('has-content');
    
    } else {
        $(this).removeClass('has-content');
    }
});
$('input, textarea').on('focusin', function () {
    $(this).addClass('focus');
});
$('input, textarea').on('focusout', function () {
    $(this).removeClass('focus');
    if ($(this).val() != '') {
        $(this).addClass('has-content');
    } else {
        $(this).removeClass('has-content');
    }
});
   // ##############################################
  // ################# footer #####################
  // ##############################################

  // $('footer .nav-link').on('click',function(e){
  //     e.preventDefault();
  // })

  let uploadBtn = $('input[type="file"]');
    uploadBtn.on('change', function(){
    let id = '#' + this.id;
    console.log(this);
    console.log($(id).parent().parent().siblings('.chosen-file'));
    let fileChosen = $(id).parent().parent().siblings('.chosen-file');
    fileChosen.append('<span class="file-name"><i class="fas fa-file"></i>' + ' ' + this.files[0].name +'<a class="remove-file"></a></span>');
    // fileChosen.append('<a class="remove-file"></a>');
    console.log(id);
    fileChosen.children().on('click',function(){
    // $(id).val('');
    // fileChosen.children().empty();
    this.remove();
  });
})
});